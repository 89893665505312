<template>
  <div>
    <v-tooltip
      v-if="!isVisible"
      left
    >
      <template #activator="{ on, attrs }">
        <v-btn
          class="selection-menu-button elevation-4"
          fab
          small
          color="grey lighten-2"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>
            mdi-select
          </v-icon>
        </v-btn>
      </template>
      <span>Выберите элементы: Shift + Click</span>
    </v-tooltip>
    <v-menu
      v-if="isVisible"
      offset-x
      nudge-left="200"
      :nudge-top="actionsFiltered.length * 50"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          class="selection-menu-button elevation-4"
          dark
          fab
          color="blue lighten-1"
          small
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>
            mdi-select
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(action, index) in actionsFiltered"
          :key="index"
          link
          @click="callFunction(action.action)"
        >
          <v-icon>{{ action.icon }}</v-icon>
          {{ action.name }}
        </v-list-item>
      </v-list>
    </v-menu>
    <component
      :is="activeComponent"
      :show.sync="showComponent"
      :module="module"
    />
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {callFunction, getJournalDocTypeByUrl, getStoreNamespaceByJournalDoc} from "@/helpers/common";
import {downloadFile, getFilenameFromContentDisposition} from "@/helpers/documents";

export default {
  data:() => ({
    showComponent:false,
    activeAction:0,
    module:null,
    actions:[
      {
        icon: "mdi-download",
        action: "downloadGroupZip",
        name: "Выгрузить документы",
        component: () => import('@/components/documents/downloadgroupzip.vue')
      },
      {
        icon: "mdi-file-pdf-box",
        action: "getMergedPdf",
        name: "Объединить PDF",
      },
      {
        icon: "mdi-content-paste",
        action: "pasteWares",
        name: "Вставить товары",
        component: () => import('@/components/documents/paste-documents-from-buffer.vue')
      },
      {
        icon: "mdi-broom",
        action: "clearDocumentsBuffer",
        name: "Очистить буфер",
      },
    ]
  }),

  computed:{
    ...mapGetters({
      documents:'buffer/getDocuments',
      wares:'buffer/getWares',
      documentTypes:'journal/getDocumentTypes',
      type:'journal/getDocumentType'
    }),
    activeComponent() {
      return this.actions[this.activeAction].component ?? null;
    },
    isVisible(){
      return !!(this.documents.length || this.wares.length)
    },
    actionsFiltered(){
      if(this.module){
        const allowedWares = this.getAllowedWares()
        return this.actions.filter(action => {
          if(action.action === "downloadGroupZip"){
            return !!this.documents.length
          }else if(action.action === "getMergedPdf"){
            return !!['sd'].includes(this.module)
          }else if(action.action === "pasteWares"){
            return !!(allowedWares.length)
          }
          return true
        })
      }
      return []
    },
  },
  watch:{
    type(){
      this.setDocumentModule(this.$route.path)
    },
    '$route':{
      handler(nv){
        this.setDocumentModule(nv.path)
      },
      deep:true,
      immediate:true
    }
  },
  methods:{
    callFunction,
    getAllowedWares(){
      return this.wares.filter(i => i.module === this.module)
    },
    setDocumentModule(path){
      if(this.$route.params.id){
        const documents = this.documentTypes.map(i => i.value)
        const [,part] = path.split('/')
        const document = getJournalDocTypeByUrl(part)
        this.module = documents.includes(document) ? document : ""
      }else {
        this.module = this.type
      }
    },
    getMergedPdf(){
      const namespace = getStoreNamespaceByJournalDoc(this.module)
      this.$store.dispatch(`${namespace}/mergedDocumentsPdf`, this.documents).then((res) => {
        const name = getFilenameFromContentDisposition(res)
        downloadFile({data:res.data, type:'application/pdf', name})
      }).catch(() => {
        this.$snackbar({
          text:'Ошибка',
          color:'red',
          top:false
        })
      })
    },
    clearDocumentsBuffer(){
      this.$store.commit('buffer/RESET_DOCUMENTS')
      this.$store.commit('buffer/RESET_WARES')
    },
    pasteWares(){
      this.activeAction = this.getActionIndex("pasteWares")
      this.showComponent = true
    },
    downloadGroupZip(){
      this.activeAction = this.getActionIndex("downloadGroupZip")
      this.showComponent = true
    },
    getActionIndex(actionId){
      const action = this.actionsFiltered.find(i => i?.action === actionId)
      return this.actions.indexOf(action)
    }
  }
}
</script>
<style scoped>
.selection-menu-button{
  position: fixed;
  right: 45px;
  bottom: 100px;
  z-index:3;
}
</style>
