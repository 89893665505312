var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"journal pb-16 mx-auto my-5"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{class:{
          'px-0':_vm.mobile
        },attrs:{"sm":"12","lg":"8"}},[_c('journal-search')],1),_c('v-col',{class:{
          'px-0':_vm.mobile
        },attrs:{"sm":"12","lg":"4"}},[_c('journal-settings')],1)],1),_c('div',[_c('v-col',{staticClass:"rounded-b-0 border-b-0 mb-0 px-0",class:{
          'pt-10':!_vm.mobile,
          'mt-n7':_vm.mobile
        },attrs:{"cols":"12"}},[_c('div',{staticClass:"d-sm-block d-lg-flex align-center"},[(!_vm.mobile)?_c('v-col',{attrs:{"sm":"12","lg":"2"}},[_c('v-btn',{staticClass:"elevation-0 rounded-l rounded-r-0",attrs:{"color":"#5CB7B1","dark":""},on:{"click":_vm.createDocument}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Создать ")],1),_c('journal-upload')],1):_vm._e(),_c('v-col',{class:{
              'px-0': _vm.mobile
            },attrs:{"sm":"12","lg":"2"}},[_c('v-select',{staticClass:"ba-0 rounded",attrs:{"color":"teal","item-color":"teal","value":_vm.documentType,"items":_vm.documentTypes,"item-text":"name","item-value":"value","hide-details":"true","background-color":_vm.mobile ? '#f5f5f5' :'white',"filled":"","rounded":"","menu-props":{
                maxHeight:'100%'
              },"label":"Тип документа"},on:{"change":_vm.selectDocumentType}})],1),_c(_vm.activeTableParams,{tag:"component"}),_c('v-col',{attrs:{"cols":"1","align-self":"center"}},[_c('v-switch',{attrs:{"color":"#5CB7B1","prepend-icon":"mdi-account-multiple","append-icon":"mdi-account","true-value":"user","false-value":"division","hide-details":"true","input-value":_vm.selectType},on:{"change":_vm.setSelectType}})],1),(_vm.showButton)?_c('v-col',{staticClass:"d-flex justify-end",class:{
              'px-0': _vm.mobile
            },attrs:{"sm":"12","lg":"2"}},[_c('v-btn',{attrs:{"color":_vm.free ? 'green': 'red',"dark":"","height":_vm.mobile ? '52': '',"width":_vm.mobile ? '100%': 'auto'},on:{"click":_vm.toggleUserFree}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])],1):_vm._e()],1)]),_c('journal-clearable-filters'),_c(_vm.activeTable,{tag:"component"})],1)],1),_c(_vm.activeComponent,{tag:"component",attrs:{"show":_vm.showComponent},on:{"update:show":function($event){_vm.showComponent=$event}}}),_c('shared-buffer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }