<template>
  <v-overlay :value="show">
    <v-card
      width="1300px"
      light
      class="pb-5"
      color="#333333"
    >
      <div class="d-flex justify-space-between py-3 px-5">
        <h3 class="py-2">
          Неудавшиеся загрузки
        </h3>
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div>
        <v-col
          class="white overflow-y-auto"
          cols="12"
          style="max-height: 500px; min-height: 250px"
        >
          <p
            v-for="(file, index) in errorFiles"
            :key="index"
          >
            {{ file }}
          </p>
        </v-col>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import modalWindowMixin from "@/mixins/modal-window.mixin";


export default {
  mixins: [modalWindowMixin],
  props: {
    errorFiles:{
      type:Array,
      required:true
    }
  },

}
</script>
