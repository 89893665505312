<template>
  <div
    class="d-flex"
    :class="{
      'mb-5': !mobile,
      'mb-0': mobile,
    }"
  >
    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <v-btn
          background-color="#EDEDED"
          class="elevation-0 my-0 rounded-r-0"
          :class="{
            'px-0': mobile,
          }"
          x-large
          dark
          v-bind="attrs"
          height="auto"
          min-width="50px"
          v-on="on"
        >
          <v-icon v-if="mobile">
            mdi-dots-vertical
          </v-icon>
          <span v-else>{{ selectedPlaceName }}</span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(place, idx) in availablePlaces"
          :key="'place-' + idx"
          @click="searchBy = place.value"
        >
          <v-list-item-title>{{ place.description }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-text-field
      v-model="searchValue"
      :disabled="!availablePlaces.length"
      hide-details="auto"
      background-color="#EDEDED"
      class="ba-0 rounded-0"
      filled
      rounded
      placeholder="Поиск"
      clearable
      @click:clear="clear"
    />
    <v-btn
      :disabled="!availablePlaces.length"
      color="#5CB7B1"
      height="auto"
      class="elevation-0 my-0 rounded-l-0 white--text"
      x-large
      :dark="!!availablePlaces.length"
      :class="{
        'px-0': mobile,
      }"
      min-width="50px"
      @click="search"
    >
      <v-icon>mdi-magnify</v-icon>
      <span class="d-none d-lg-block">Поиск</span>
    </v-btn>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {getJournalSearchPlaces} from "@/helpers/journal";

export default {
  data: () => ({
    searchValue: "",
    searchBy: "",
    searchPlaces: getJournalSearchPlaces()
  }),
  computed: {
    ...mapGetters({
      documentType: "journal/getDocumentType",
      searchParams: "journal/getSearch",
      pagination: "journal/pagination",
    }),
    availablePlaces() {
      return this.searchPlaces.filter((i) => {
        return i.available.includes(this.documentType);
      });
    },
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    selectedPlaceName() {
      return this.searchPlaces.find((i) => i.value === this.searchBy)?.name;
    },
  },
  watch: {
    searchParams: {
      handler({searchValue, searchBy}) {
        this.searchValue = searchValue;
        this.searchBy = searchBy;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    document.addEventListener("keyup", this.onEnter);
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this.onEnter);
  },
  methods: {
    onEnter(e) {
      if (e.key === "Enter") {
        this.search();
      }
    },
    search() {
      if (!this.availablePlaces) return false;
      // При поиске по кнопке страница = 1, чтобы были видны записи
      // Сам поиск записывам в стор чтобы можно было использовать данные при просмотре последующих страниц
      this.$store.commit("journal/SET_PAGINATION", {
        page: 1,
      });
      this.$store.commit("journal/SET_SEARCH", {
        searchBy: this.searchBy,
        searchValue: this.searchValue,
      });
      this.$store.dispatch("journal/fetchItems");
    },
    clear() {
      this.$store.commit("journal/SET_SEARCH", {
        searchBy: this.searchParams.searchBy,
        searchValue: null,
      });
      this.$store.dispatch("journal/fetchItems");
    },
  },
};
</script>
