<template>
  <v-col class="pt-0">
    <v-chip
      v-if="dateText"
      class="ma-2"
      @click="clearDates()"
    >
      {{ dateText }}
      <v-icon
        right
        small
      >
        mdi-close-circle
      </v-icon>
    </v-chip>
    <v-chip
      v-if="filter.trash === true "
      class="ma-2"
      @click="setParam('trash')"
    >
      Удаленные
      <v-icon
        right
        small
      >
        mdi-close-circle
      </v-icon>
    </v-chip>
    <v-chip
      v-if="filter.controlled === true "
      class="ma-2"
      @click="setParam('controlled')"
    >
      На контроле
      <v-icon
        right
        small
      >
        mdi-close-circle
      </v-icon>
    </v-chip>
    <v-chip
      v-if="filter.favorite === true "
      class="ma-2"
      @click="setParam('favorite')"
    >
      Избранные
      <v-icon
        right
        small
      >
        mdi-close-circle
      </v-icon>
    </v-chip>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from "moment";
export default {
  data:() => ({
    filter:{
      status:"",
      declarationDateFrom:null,
      declarationDateTo:null,
      favorite:false,
      controlled:false,
      trash:false,
    },
  }),
  computed: {
    ...mapGetters({
      params:'journal/getFilter',
    }),
    dateText(){
      if(this.filter.declarationDateFrom && this.filter.declarationDateTo){
        return `C ${moment(this.filter.declarationDateFrom).format('DD.MM.YYYY')} по ${moment(this.filter.declarationDateTo).format('DD.MM.YYYY')}`
      }
      if(this.filter.declarationDateFrom){
        return `C ${moment(this.filter.declarationDateFrom).format('DD.MM.YYYY')}`
      }
      if(this.filter.declarationDateFrom){
        return`По ${moment(this.filter.declarationDateTo).format('DD.MM.YYYY')}`
      }
      return null
    }
  },
  watch:{
    params:{
      handler(nv){
        this.filter = nv
      },
      immediate:true,
      deep:true,
    }
  },
  methods: {
    clearDates(){
      this.$store.commit('journal/SET_FILTER', {
        ...this.filter,
        declarationDateFrom:null,
        declarationDateTo:null,
      })
      this.$store.commit('journal/SET_PAGINATION', {page: 1})
      this.$store.dispatch('journal/fetchItems')
    },
    setParam(type){
      this.filter[type] = !this.filter[type]
      this.$store.commit('journal/SET_FILTER', this.filter)
      this.$store.commit('journal/SET_PAGINATION', {page: 1})
      this.$store.dispatch('journal/fetchItems')
    },
  }
}
</script>