<template>
  <section
    class="journal pb-16 mx-auto my-5"
  >
    <v-container fluid>
      <v-row>
        <v-col
          sm="12"
          lg="8"
          :class="{
            'px-0':mobile
          }"
        >
          <journal-search />
        </v-col>
        <v-col
          sm="12"
          lg="4"
          :class="{
            'px-0':mobile
          }"
        >
          <journal-settings />
        </v-col>
      </v-row>

      <div>
        <v-col
          cols="12"
          class="rounded-b-0 border-b-0 mb-0 px-0"
          :class="{
            'pt-10':!mobile,
            'mt-n7':mobile
          }"
        >
          <div
            class="d-sm-block d-lg-flex align-center"
          >
            <v-col
              v-if="!mobile"
              sm="12"
              lg="2"
            >
              <v-btn
                color="#5CB7B1"
                dark
                class="elevation-0 rounded-l rounded-r-0"
                @click="createDocument"
              >
                <v-icon>mdi-plus</v-icon>
                Создать
              </v-btn>
              <journal-upload />
            </v-col>
            <v-col
              sm="12"
              lg="2"
              :class="{
                'px-0': mobile
              }"
            >
              <v-select
                color="teal"
                item-color="teal"
                :value="documentType"
                :items="documentTypes"
                item-text="name"
                item-value="value"
                hide-details="true"
                :background-color="mobile ? '#f5f5f5' :'white'"
                class="ba-0 rounded"
                filled
                rounded
                :menu-props="{
                  maxHeight:'100%'
                }"
                label="Тип документа"
                @change="selectDocumentType"
              />
            </v-col>


            <component :is="activeTableParams" />
            <v-col
              cols="1"
              align-self="center"
            >
              <v-switch
                color="#5CB7B1"
                prepend-icon="mdi-account-multiple"
                append-icon="mdi-account"
                true-value="user"
                false-value="division"
                hide-details="true"
                :input-value="selectType"
                @change="setSelectType"
              />
            </v-col>
            <v-col
              v-if="showButton"
              sm="12"
              lg="2"
              class="d-flex justify-end"
              :class="{
                'px-0': mobile
              }"
            >
              <v-btn
                :color="free ? 'green': 'red'"
                dark
                :height="mobile ? '52': ''"
                :width=" mobile ? '100%': 'auto'"
                @click="toggleUserFree"
              >
                {{ buttonText }}
              </v-btn>
            </v-col>
          </div>
        </v-col>
        <journal-clearable-filters />
        <component :is="activeTable" />
      </div>
    </v-container>
    <component
      :is="activeComponent"
      :show.sync="showComponent"
    />
    <shared-buffer />
  </section>
</template>
<script>
import {mapGetters} from "vuex";
import JournalUpload from '@/components/journal/journal-upload.vue'
import JournalSearch from '@/components/journal/journal-search.vue'
import JournalSettings from '@/components/journal/journal-settings.vue'
import JournalClearableFilters from '@/components/journal/journal-clearable-filters.vue'
import {division} from '@/helpers/socket'
import SharedBuffer from '@/components/shared/shared-buffer.vue'
import {getDefaultSearchPlaceForType} from "@/helpers/journal";

export default {
  components:{
    SharedBuffer,
    JournalSearch,
    JournalSettings,
    JournalUpload,
    JournalClearableFilters
  },
  data: () => ({
    socket:null,
    dialog: false,
    procedure:"",
    showComponent:false,
    activeComponent:null,
    actions:[
      {
        action:'createDocument',
        component:() => import('@/components/journal/journal-document-create.vue')
      }
    ],
    tableParams:[
      {
        value:'sd',
        component: () => import('@/components/statistics/journal/journal-params.vue')
      },
      {
        value:'td',
        component: () => import('@/components/transit/journal/journal-params.vue')
      },
      {
        value:'epi',
        component: () => import('@/components/epi/journal/journal-params.vue')
      },
      {
        value:'dt',
        component: () => import('@/components/dt/journal/journal-params.vue')
      },
      {
        value:'zvt',
        component: () => import('@/components/zvt/journal/journal-params.vue')
      }
    ],
    tables:[
      {
        value:'sd',
        component: () => import('@/components/statistics/journal/journal-table.vue')
      },
      {
        value:'td',
        component: () => import('@/components/transit/journal/journal-table.vue')
      },
      {
        value:'so',
        component: () => import('@/components/ensuring/journal/journal-table.vue')
      },
      {
        value:'rs',
        component: () => import('@/components/rs/journal/journal-table.vue')
      },
      {
        value:'epi',
        component: () => import('@/components/epi/journal/journal-table.vue')
      },
      {
        value:'ptd',
        component: () => import('@/components/passenger/journal/journal-table.vue')
      },
      {
        value:'sez',
        component: () => import('@/components/sez/journal/journal-table.vue')
      },
      {
        value:'dt',
        component: () => import('@/components/dt/journal/journal-table.vue')
      },
      {
        value:'uvr',
        component: () => import('@/components/uvr/journal/journal-table.vue')
      },
      {
        value:'pass',
        component: () => import('@/components/pass/journal/journal-table.vue')
      },
      {
        value:'dts',
        component: () => import('@/components/dts/journal/journal-table.vue')
      },
      {
        value:'zvt',
        component: () => import('@/components/zvt/journal/journal-table.vue')
      },
    ],
    interval: null
  }),
  computed: {
    activeTable(){
      if(!this.documentType) return null
      return this.tables.find(i =>i.value === this.documentType)?.component ?? null
    },
    activeTableParams(){
      if(!this.documentType) return null
      return this.tableParams.find(i =>i.value === this.documentType)?.component ?? null
    },
    ...mapGetters({
      user: 'auth/getUser',
      dvsn: 'auth/getSingleDivision',
      items:'journal/getItems',
      pagination:"journal/pagination",
      isUserFree:'experiment/getUserFree',
      documentTypes:'journal/getDocumentTypes', // Список
      documentType: 'journal/getDocumentType', // Активный
      selectType:'journal/getSelectType'
    }),
    free(){
      return this.isUserFree === null || !(this.currentExist)
    },
    currentExist(){
      return typeof this.isUserFree === 'object' && this.isUserFree !== null && this.documentType in this.isUserFree && this.isUserFree[this.documentType] !== null
    },
    showButton(){
      const applications = this.items.filter(item => item.client_division_id && item.client_division_id !== null && item.user_id === null)
      const hasApplications = !!applications.length
      return (hasApplications && this.dvsn?.divisionType === 'BTS') || this.currentExist
    },
    buttonText(){
      return this.free ? 'Свободен !': `${this.isUserFree[this.documentType]} : выполнено !`
    },
    activeDivisionId(){
      return this.user?.user?.activeDivisionId || ""
    },
    mobile(){
      return this.$vuetify.breakpoint.mobile
    }
  },
  watch:{
    "dvsn.id":{ // Переподписываемся на сокет дивижена при смене компании  + получаем список документов
      handler(nv){
        if(nv){
          // *** при пагинации и смене компании запрос может уйти дважды тк в таблице обработается изменение - надо потом поправить ***
          if(this.socket !== null && Object.keys(this.socket)){
            this.socket.unsubscribe()
          }
          this.socket = division.call(this)
          this.socket.subscribe()
          if(this.pagination.page === 1 && this.pagination.size === 15){ // Если дефолтные параметры то просто обновляем данные
            clearInterval(this.interval)
            this.$store.dispatch("journal/fetchItems");
            if(this.documentType === 'rs'){
              this.interval = setInterval(() => {
                this.$store.dispatch('journal/fetchItems')
              }, 30000)
            }
          }else{ // Если нет - то меняет на стандартные - изменение отследится в компоненте таблицы и вызовет обновление данных
            clearInterval(this.interval)
            this.$store.commit('journal/SET_PAGINATION', {page:1, size:this.pagination.size})
            this.$store.dispatch("journal/fetchItems");
            // временный таймер для рски
            if(this.documentType === 'rs'){
              this.interval = setInterval(() => {
                this.$store.dispatch('journal/fetchItems')
              }, 30000)
            }
          }
        }
      },
      immediate:true
    }
  },
  created() {
    this.setJournalTitle()
    this.setAvailableDocumentTypes()
    this.$store.dispatch('auth/loadSingleDivisions', this.user.user.activeDivisionId)
    this.$store.dispatch('auth/loadPurchasedServices', this.user.user.activeDivisionId)
  },
  beforeDestroy(){
    this.socket.unsubscribe()
  },
  methods: {
    setJournalTitle(){
      document.title = 'ЖУРНАЛ'
    },
    setAvailableDocumentTypes(){
      this.$store.commit('journal/SET_AVAILABLE_DOCUMENT_TYPES')
    },
    setSelectType(type){
      this.$store.commit('journal/SET_SELECT_TYPE', type)
      this.$store.dispatch('journal/fetchItems')
    },
    clearBuffer(){
      this.$store.commit('buffer/RESET_DOCUMENTS')
      this.$store.commit('buffer/RESET_WARES')
    },
    selectDocumentType(value){
      clearInterval(this.interval)
      this.clearBuffer()
      const searchBy = getDefaultSearchPlaceForType(value)
      this.$store.commit('journal/SET_SEARCH', { searchBy })
      this.$store.commit('journal/SET_PAGINATION', {page:1})
      this.$store.commit('journal/SET_DOCUMENT_TYPE', value)
      this.$store.dispatch('journal/fetchItems')

      // временный таймер для рски
      if(value === 'rs'){
        this.interval = setInterval(() => {
          this.$store.dispatch('journal/fetchItems')
        }, 30000)
      }
    },
    createDocument(){
      const action = this.actions.find(i => i.action === 'createDocument')
      this.activeComponent = action.component || null
      this.showComponent = true
    },
    toggleUserFree(){
      if(this.free){
        this.$store.dispatch('experiment/getFreeApplication').then((res) => {
          if(res.data){
            this.$snackbar({
              text: `В работу взят документ - ${res.data}`,
              color: "primary",
              timeout: "5000",
            });
            this.$store.dispatch("journal/fetchItems");
          }
          this.$warning('Отсутствуют доступные заявки')
        }).catch(() => this.$error())
      }else{
        this.$store.commit('experiment/UNSET_USER_FREE', this.documentType)
      }
    },
  },
};
</script>
<style scoped>
  .journal{
    width:100%;
    max-width: 1440px;
    min-height: calc(100vh - 129px)
  }
</style>
