<template>
  <div
    class="filter__layout"
  >
    <transition name="slide-fade">
      <div
        v-if="show"
        :class="{
          'filter__card':!mobile,
          'filter__card-mobile':mobile
        }"
        class=" elevation-1 rounded-b"
      >
        <div class="filter__card-head d-flex  rounded-t pa-5 justify-space-between align-center">
          <v-btn
            dark
            text
            min-width="35px"
            max-width="35px"
            @click="$emit('update:show',false)"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>

          <h3>Фильтр</h3>
          <v-btn
            dark
            text
          >
            <v-icon>mdi-autorenew</v-icon>
          </v-btn>
        </div>
        <div class="filter__card-body pa-5">
          <label>
            Статус
            <v-select
              v-model="filter.statuses"
              :items="statuses"
              :item-text="'name'"
              :item-value="'value'"
              outlined
              dense
              hide-details="auto"
              @change="updateJournal"
            />
          </label>
          <v-row>
            <v-col>
              <label>
                C
                <custom-date-picker
                  :value="filter.declarationDateFrom"
                  @change="(v) => onDateChange('declarationDateFrom', v)"
                />
                <label />
              </label>
            </v-col>
            <v-col>
              <label>
                По
                <custom-date-picker
                  :value="filter.declarationDateTo"
                  @change="(v) => onDateChange('declarationDateTo', v)"
                />
                <label />
              </label>
            </v-col>
          </v-row>
          <div class="d-flex mt-5">
            <v-btn
              text
              @click="setParam('trash')"
            >
              <v-icon :color=" filter.trash=== true ? 'blue' : ''">
                mdi-delete
              </v-icon>
            </v-btn>
            <v-btn
              :color=" filter.controlled=== true ? 'blue' : ''"
              text
              @click="setParam('controlled')"
            >
              <v-icon>mdi-bell</v-icon>
            </v-btn>
            <v-btn
              :color=" filter.favorite=== true ? 'blue' : ''"
              text
              @click="setParam('favorite')"
            >
              <v-icon>mdi-star</v-icon>
            </v-btn>
          </div>
          <v-btn
            class="my-5"
            text
            @click="unsetFilter"
          >
            Сбросить
          </v-btn>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";
import {filterDefault} from "@/helpers/journal"

export default {
  components: {CustomDatePicker},
  props: {
    show: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    filter: filterDefault(),
    statuses: [
      {
        name: 'По умолчанию',
        value: ''
      },
      {
        name: 'Зарегистрированные',
        value: '5',
      },
      {
        name: 'Выпущенные',
        value: '8',
      },
      {
        name: 'Отказные',
        value: '2,7,11',
      },
      {
        name: 'Без статуса ИСТО',
        value: 'NO_STATUS_NCES',
      }
    ]
  }),
  computed: {
    ...mapGetters({
      params: 'journal/getFilter',
    }),
    mobile() {
      return this.$vuetify.breakpoint.mobile
    }
  },
  watch: {
    params: {
      handler(nv) {
        this.filter = nv
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    onDateChange(key, value) {
      this.filter[key] = value
      this.updateJournal()
    },
    setParam(type) {
      this.filter[type] = !this.filter[type]
      this.$store.commit('journal/SET_FILTER', this.filter)
      this.$store.commit('journal/SET_PAGINATION', {page: 1})
      this.$store.dispatch('journal/fetchItems')
    },
    updateJournal() {
      this.$store.commit('journal/SET_FILTER', this.filter)
      this.$store.commit('journal/SET_PAGINATION', {page: 1})
      this.$store.dispatch('journal/fetchItems')
    },
    unsetFilter() {
      this.$store.commit('journal/UNSET_FILTER')
      this.$store.commit('journal/SET_PAGINATION', {page: 1})
      this.$store.dispatch('journal/fetchItems')
    }
  }
}
</script>
<style scoped>
.filter__layout {
  position: fixed;
  top: 110px;
  right: 1%;
  z-index: 20;
  background-color: white;
}

.filter__card {
  max-width: 500px;
  min-width: 400px;
  width: 500px;
}

.filter__card-mobile {
  width: calc(100vw - 10px);
}

.filter__card-head {
  background-color: #272727;
  color: white;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}
</style>
