<template>
  <div class="d-flex mb-5">
    <pagination-size-button
      rounded="left"
      :value="pagination.size"
      @select="setPagination"
    />

    <v-tooltip
      v-for="(btn, idx) in visibleButtons"
      :key="idx"
      bottom
    >
      <template #activator="{ on, attrs }">
        <v-btn
          class="elevation-0  rounded-0 settings__button"
          :class="{ 'rounded-r rounded-l-0':idx === buttons.length -1 }"

          height="56px"
          x-large
          v-bind="attrs"
          v-on="on"
          @click="callFunction(btn.action)"
        >
          <v-icon v-if="btn.icon">
            {{ btn.icon }}
          </v-icon>
          <span v-if="btn.text">{{ btn.text }}</span>
        </v-btn>
      </template>
      <span v-if="btn.hint">{{ btn.hint }}</span>
    </v-tooltip>

    <journal-filter :show.sync="showFilter" />
  </div>
</template>
<script>
import JournalFilter from '@/components/journal/journal-filter.vue'
import { mapGetters } from 'vuex';
import PaginationSizeButton from "@/components/shared/pagination-size-button.vue";
export default {
  components:{
    PaginationSizeButton,
    JournalFilter
  },
  data:() => ({
    showFilter:false,
    buttons:[
      { icon: "mdi-filter", action: "toggleFilter", value:"", hint:"Фильтр", mobile:true },
      { icon: "mdi-calendar", action: "showReports", value:"", hint:"Отчеты", mobile:false},
      { icon: "mdi-book-open-blank-variant", action: "showCatalogs", value:"", hint:"Справочники", mobile:false},
      { icon: "mdi-cog", action: "showSettings", text:"", hint:"Настройки", mobile:true },
    ]
  }),
  computed:{
    ...mapGetters({
      pagination:"journal/pagination",
    }),
    mobile(){
      return this.$vuetify.breakpoint.mobile
    },
    visibleButtons(){
      return this.mobile ? this.buttons.filter(i => i.mobile === true) : this.buttons
    }
  },
  methods:{
    showSettings(){
      this.$router.push({name:"SettingsHomepage"})
    },
    showCatalogs(){
      this.$router.push({name:"CatalogsHomepage"})
    },
    showReports(){
      this.$router.push({name:"ReportsHomepage"})
    },
    setPagination(size){
      this.$store.commit('journal/SET_PAGINATION', {size})
      if(this.mobile){
        this.$store.dispatch('journal/fetchItems')
      }
    },
    callFunction(func){
      func.length ? this[func]() : false;
    },
    toggleFilter(){
      this.showFilter = !this.showFilter
    },
  }
}
</script>
<style scoped>
.settings__button{
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0!important;
}
.cursor-pointer{
  cursor: pointer;
}
</style>
