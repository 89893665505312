<template>
  <div class="d-inline-block">
    <v-btn
      class="elevation-0 rounded-r rounded-l-0"
      @click="clickInputFile"
    >
      <v-icon>mdi-upload-outline</v-icon>
    </v-btn>
    <input
      ref="fileInput"
      class="d-none"
      type="file"
      accept="text/xml, application/zip"
      @change="select"
    >
    <component
      :is="activeComponent"
      :error-files="errorFiles"
      :show.sync="show"
    />
  </div>
</template>

<script>
import axios from "axios";
import {HELPER} from "@/http-routes";
import JournalUploadErrors from "@/components/journal/journal-upload-errors.vue";
import {getJournalDocTypeByService} from "@/helpers/common";
import {mapGetters} from "vuex";

export default {
  data(){
    return{
      show:false,
      activeComponent:null,
      errorFiles:[]
    }
  },
  computed:{
    ...mapGetters({
      user:'auth/getUser'
    })
  },
  methods: {
    uploadWithHelper(file){
      const formData = new FormData()
      formData.append('file', file)
      return axios.post(`${HELPER}xml/import?userId=${this.user.user.id}`, formData)
        .then((res) => {
          const {type, errorFiles} = res.data
          this.$success("Файл загружен")
          this.showErrorFiles(errorFiles)
          return type
        })
        .catch(() => this.$error('Не удалось загрузить документ'))
    },
    showErrorFiles(errorFiles){
      if(!errorFiles.length) return
      this.activeComponent = JournalUploadErrors
      this.errorFiles = errorFiles
      this.show = true
    },
    clickInputFile() {
      this.$refs.fileInput.click();
    },
    clearFileInput(){
      this.$refs.fileInput.value = ""
    },
    updateJournal(journalDocumentType){
      this.$store.commit('journal/SET_DOCUMENT_TYPE', journalDocumentType)
      this.$store.dispatch('journal/fetchItems')
    },
    select(event) {
      const [file] = event.target.files;
      if (!file) return;

      this.uploadWithHelper(file)
        .then((type) => {
          const journalType = getJournalDocTypeByService(type)
          this.updateJournal(journalType)
        })
        .finally(this.clearFileInput)
    },
  },

}
</script>
